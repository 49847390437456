import { Typography, TypographyProps } from '@material-ui/core';
import styled from 'styled-components';

interface LinkProps extends TypographyProps {
  href?: string;
  fontWeight?: number;
  newTab?: boolean;
  component?: any;
}

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const UndecoratedLink: React.FunctionComponent<LinkProps> = ({
  children,
  href,
  newTab,
  variant,
  component,
}) => {
  return (
    <StyledAnchor href={href} {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : '')}>
      <Typography component={component} variant={variant ? variant : 'inherit'}>
        {children}
      </Typography>
    </StyledAnchor>
  );
};
