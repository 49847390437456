import * as React from 'react';

import MuiIcon from '@material-ui/core/Icon';
import { OptimizedImage } from '@app/components/optimized-image';

interface IconProps {
  /** An URL (for SVG icons) or the unique icon identifer on Material UI
   *
   * Use the exact identification of the icon as displayed here:
   * https://material.io/resources/icons/?style=baseline
   *
   * The icon can also be prefixed with custom-<icon name>, this way we will
   * look for it on '@app/public/icons/' directory for the icon.
   */
  icon?: string | null | React.ReactNode;
  /** Icon width */
  width?: string;
  /** Icon height */
  height?: string;
  /** Alternative description for the icon */
  alt?: string;
  /** A custom className for that Icon */
  className?: string;
  /** Size for the Icon */
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
  /** Should it include the svg directly */
  include?: boolean;
  /** Customized styles */
  style?: React.CSSProperties | undefined;
}

export const Icon: React.FunctionComponent<IconProps> = ({
  icon,
  alt,
  height,
  width,
  className,
  fontSize,
  include,
  style,
}) => {
  if (icon != null && typeof icon === 'string') {
    if (icon.match(/^custom-/)) {
      icon = icon.replace('custom-', '') + '.svg';
      if (include) {
        icon = require('../../public/icons/' + icon + '?include');
      } else {
        icon = require('../../public/icons/' + icon + '?url');
      }
    }

    if (
      typeof icon === 'string' &&
      (icon!.match(/(^data:|^\/\/|^https:)/) || icon.match(/\<svg/) || icon.match(/\.svg/))
    ) {
      return (
        <OptimizedImage
          src={icon}
          alt={alt}
          height={height}
          width={width}
          ext="svg"
          include={include}
          className={className}
        />
      );
    }
  }

  return (
    <MuiIcon style={style} className={className} color="inherit" fontSize={fontSize}>
      {icon}
    </MuiIcon>
  );
};

export default Icon;
